import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftRight, Container, Flame, LayoutDashboard, Package, Package2, ShoppingCart, Users } from "lucide-react";
import { Badge } from "../components/ui/badge";
import PAKKmaxLogo from "./PAKKmaxLogo";

export const SideNavBar = ({ ...props }) => {
  const location = useLocation();

  // Function to determine if a link is active
  const isActive = (path) => (location.pathname).includes(path);

  return (
    <div className="fixed top-0 left-0 h-full w-[220px] lg:w-[280px] hidden border-r bg-muted/40 md:block z-20">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          <PAKKmaxLogo />
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-lg font-medium lg:px-4">
            {/* <Link
              to={`${process.env.PUBLIC_URL}/dashboard`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`dashboard`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <LayoutDashboard className="h-5 w-5" />
              Dashboard
            </Link> */}

            <Link
              to={`${process.env.PUBLIC_URL}/orders`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`orders`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <ShoppingCart className="h-5 w-5" />
              Orders
              {/* <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
                6
              </Badge> */}
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/products`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`${process.env.PUBLIC_URL}/products`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Package className="h-5 w-5" />
              Products
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/deals`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`deals`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Flame className="h-5 w-5" />
              Deals
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/shipments`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`shipments`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Container className="h-5 w-5" />
              Shipments
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/team`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`team`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <Users className="h-5 w-5" />
              Team
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}/exchange-rates`}
              className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all ${
                isActive(`exchange-rates`)
                  ? "bg-muted text-primary"
                  : "text-muted-foreground hover:text-primary"
              }`}
            >
              <ArrowLeftRight className="h-5 w-5" />
              Exchange Rates
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};
