import { useEffect, useState } from "react"
import { useForm, useFieldArray, FormProvider, useWatch, Controller } from "react-hook-form"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog"
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { Ship, Plane, AlertCircle, Trash2, CircleX } from 'lucide-react'
import { baseURLs } from "../../misc/constants"
import axios from "axios"
import { getAxiosHeaders, validateUrl } from "../../misc/utils"
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert"
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form"
import { Separator } from "../../components/ui/separator"
import { Card } from "../../components/ui/card"
import { AlertDialogAction } from "@radix-ui/react-alert-dialog"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog"
import { Textarea } from "../../components/ui/textarea"
import { DateTimePicker } from "./DatePicker"
import moment from "moment"

export const DealOrderedShipmentDialog = ({toggleModal, orders, getDealOrders}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      order_number: "",
      shipment_type: "",
      note: "",
      date: "",
      alert_customer: true
    },
  });

  const onSubmit = (data) => {
    console.log("Submitted:", data)
    console.log(orders);

    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/deal/ordered`, {
      order_number: data.order_number,
      orders: JSON.stringify(orders),
      shipment_type: data.shipment_type,
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no',
    }, getAxiosHeaders())
    .then((response) => {
      getDealOrders();
      toggleModal("successOrderDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.order_number) {
          setError('order_number', {
            type: 'server',
            message: responseErrors.order_number
          });
        }

        if (responseErrors?.shipment_type) {
          setError('shipment_type', {
            type: 'server',
            message: responseErrors.shipment_type
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  const shipmentType = watch("shipment_type")

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Shipment Details</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Order Number</Label>
            <Input
              id="order_number"
              {...register("order_number", { 
                required: "Order number is required",
                pattern: {
                  value: /^[A-Za-z0-9]{6,}$/,
                  message: "Order number must be at least 6 alphanumeric characters"
                }
              })}
              placeholder="Enter order number"
            />
            {errors.order_number && (
              <p className="text-sm text-red-500">{errors.order_number.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label>Shipment Type</Label>
            <div className="flex space-x-2">
              <Button
                type="button"
                variant={shipmentType === "sea" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "sea")}
              >
                <Ship className="mr-2 h-4 w-4" />
                Sea
              </Button>
              <Button
                type="button"
                variant={shipmentType === "air" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "air")}
              >
                <Plane className="mr-2 h-4 w-4" />
                Air
              </Button>
            </div>
            <input
              type="hidden"
              {...register("shipment_type", { required: "Shipment type is required" })}
            />
            {errors.shipment_type && (
              <p className="text-sm text-red-500">{errors.shipment_type.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="note">Note</Label>
            <Textarea
              id="note"
              {...register("note")}
              placeholder="Enter note"
            />
            {errors.note && (
              <p className="text-sm text-red-500">{errors.note.message}</p>
            )}
          </div>

          {/* Date */}
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <div>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field }) => <DateTimePicker field={field} />}
              />
              {errors.date && (
                <p className="text-sm text-red-500">{errors.date.message}</p>
              )}
            </div>
          </div>

          {/* Alert Customer Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="alert_customer"
              {...register("alert_customer")}
              className="h-4 w-4"
            />
            <Label htmlFor="alert_customer">Alert Customer</Label>
          </div>
          {errors.alert_customer && (
            <p className="text-sm text-red-500">{errors.alert_customer.message}</p>
          )}

          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const CustomOrderedShipmentDialog = ({toggleModal, orders, getOrderDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      order_number: "",
      quantity: "",
      shipment_type: "",
      note: "",
      date: "",
      alert_customer: true
    },
  });

  const onSubmit = (data) => {
    console.log("Submitted:", data)
    console.log(orders);

    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/ordered`, {
      order_number: data.order_number,
      items: JSON.stringify(orders),
      quantity: data.quantity,
      shipment_type: data.shipment_type,
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no'
    }, getAxiosHeaders())
    .then((response) => {
      getOrderDetails();
      toggleModal("successOrderDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.order_number) {
          setError('order_number', {
            type: 'server',
            message: responseErrors.order_number
          });
        }

        if (responseErrors?.quantity) {
          setError('quantity', {
            type: 'server',
            message: responseErrors.quantity
          });
        }

        if (responseErrors?.shipment_type) {
          setError('shipment_type', {
            type: 'server',
            message: responseErrors.shipment_type
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  const shipmentType = watch("shipment_type")

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Shipment Details</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Order Number</Label>
            <Input
              id="order_number"
              {...register("order_number", { 
                required: "Order number is required",
                pattern: {
                  value: /^[A-Za-z0-9]{6,}$/,
                  message: "Order number must be at least 6 alphanumeric characters"
                }
              })}
              placeholder="Enter order number"
            />
            {errors.order_number && (
              <p className="text-sm text-red-500">{errors.order_number.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              id="quantity"
              {...register("quantity", { 
                required: "quantity is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "quantity must be a number"
                }
              })}
              placeholder="Enter quantity"
            />
            {errors.quantity && (
              <p className="text-sm text-red-500">{errors.quantity.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label>Shipment Type</Label>
            <div className="flex space-x-2">
              <Button
                type="button"
                variant={shipmentType === "sea" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "sea")}
              >
                <Ship className="mr-2 h-4 w-4" />
                Sea
              </Button>
              <Button
                type="button"
                variant={shipmentType === "air" ? "primaryOutline" : "outline"}
                className="flex-1"
                onClick={() => setValue("shipment_type", "air")}
              >
                <Plane className="mr-2 h-4 w-4" />
                Air
              </Button>
            </div>
            <input
              type="hidden"
              {...register("shipment_type", { required: "Shipment type is required" })}
            />
            {errors.shipment_type && (
              <p className="text-sm text-red-500">{errors.shipment_type.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="note">Note</Label>
            <Textarea
              id="note"
              {...register("note")}
              placeholder="Enter note"
            />
            {errors.note && (
              <p className="text-sm text-red-500">{errors.note.message}</p>
            )}
          </div>

          {/* Date */}
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <div>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field }) => <DateTimePicker field={field} />}
              />
              {errors.date && (
                <p className="text-sm text-red-500">{errors.date.message}</p>
              )}
            </div>
          </div>

          {/* Alert Customer Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="alert_customer"
              {...register("alert_customer")}
              className="h-4 w-4"
            />
            <Label htmlFor="alert_customer">Alert Customer</Label>
          </div>
          {errors.alert_customer && (
            <p className="text-sm text-red-500">{errors.alert_customer.message}</p>
          )}

          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const EditSourcedProductQuantityDialog = ({toggleModal, sourcedProduct, setSelectedSourcedProduct, getOrderDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      quantity: 0,
    },
  });

  const onSubmit = (data) => {
    console.log("Submitted:", data);

    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/approved-product/${sourcedProduct.order_product_id}`, {
      quantity: data.quantity,
    }, getAxiosHeaders())
    .then((response) => {
      getOrderDetails();
      toggleModal("successOrderProductUpdateDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        if (responseErrors?.quantity) {
          setError('quantity', {
            type: 'server',
            message: responseErrors.quantity
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => {toggleModal(null); setSelectedSourcedProduct(null)}}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Product Approval</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <div className="flex items-center gap-4 py-6 cursor-pointer">
              <img
                src={sourcedProduct.image}
                alt={sourcedProduct.product_name}
                className="w-20 h-20 rounded"
              />
              <div>
                <p className="text-lg font-medium">{sourcedProduct.product_name}</p>
                <p className="text-md text-gray-500">{`GHS ${sourcedProduct.unit_cost} | MOQ: ${sourcedProduct.moq}`}</p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              id="quantity"
              {...register("quantity", { 
                required: "quantity is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "quantity must be a number"
                }
              })}
              placeholder="Enter quantity"
            />
            {errors.quantity && (
              <p className="text-sm text-red-500">{errors.quantity.message}</p>
            )}
          </div>

          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

// export const EditOrderedShipmentDialog = ({toggleModal, logInfo, getOrderDetails}) => {
//   const [requesting, setRequesting] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const { register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
//     defaultValues: {
//       order_number: logInfo.order_number,
//       quantity: logInfo.quantity,
//       shipment_type: logInfo.shipment_type,
//       note: logInfo.note
//     },
//   });

//   const onSubmit = (data) => {
//     console.log("Submitted:", data)
//     console.log(orders);

//     setRequesting(true);
//     setErrorMessage('');

//     axios.put(baseURLs.API_URL + `/orders/ordered/log/${logInfo.log_id}`, {
//       order_number: data.order_number,
//       quantity: data.quantity,
//       shipment_type: data.shipment_type,
//       note: data.note
//     }, getAxiosHeaders())
//     .then((response) => {
//       getOrderDetails();
//       toggleModal("successOrderDialog");
//     })
//     .catch((error) => {
//       if (error.response && error.response.data) {
//         const responseErrors = error.response.data.errors || null;
//         setErrorMessage(error.response.data.error);

//         // Check for specific field errors and set them
//         if (responseErrors?.order_number) {
//           setError('order_number', {
//             type: 'server',
//             message: responseErrors.order_number
//           });
//         }

//         if (responseErrors?.quantity) {
//           setError('quantity', {
//             type: 'server',
//             message: responseErrors.quantity
//           });
//         }

//         if (responseErrors?.shipment_type) {
//           setError('shipment_type', {
//             type: 'server',
//             message: responseErrors.shipment_type
//           });
//         }

//         if (responseErrors?.note) {
//           setError('note', {
//             type: 'server',
//             message: responseErrors.note
//           });
//         }
//       }
//     })
//     .finally(() => {
//       setRequesting(false);
//     });
//   }

//   const shipmentType = watch("shipment_type")

//   return (
//     <Dialog open={true} onOpenChange={() => toggleModal(null)}>
//       <DialogContent className="sm:max-w-[425px]">
//         <DialogHeader>
//           <DialogTitle>Shipment Details</DialogTitle>
//         </DialogHeader>
//         {
//           errorMessage &&
//           <Alert variant="destructive">
//             <AlertCircle className="h-4 w-4" />
//             <AlertTitle>Error</AlertTitle>
//             <AlertDescription>
//               {errorMessage}
//             </AlertDescription>
//           </Alert>
//         }
//         <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
//           <div className="space-y-2">
//             <Label htmlFor="order_number">Order Number</Label>
//             <Input
//               id="order_number"
//               {...register("order_number", { 
//                 required: "Order number is required",
//                 pattern: {
//                   value: /^[A-Za-z0-9]{6,}$/,
//                   message: "Order number must be at least 6 alphanumeric characters"
//                 }
//               })}
//               placeholder="Enter order number"
//             />
//             {errors.order_number && (
//               <p className="text-sm text-red-500">{errors.order_number.message}</p>
//             )}
//           </div>
//           <div className="space-y-2">
//             <Label htmlFor="quantity">Quantity</Label>
//             <Input
//               id="quantity"
//               {...register("quantity", { 
//                 required: "quantity is required",
//                 pattern: {
//                   value: /^[0-9]+$/,
//                   message: "quantity must be a number"
//                 }
//               })}
//               placeholder="Enter quantity"
//             />
//             {errors.quantity && (
//               <p className="text-sm text-red-500">{errors.quantity.message}</p>
//             )}
//           </div>
//           <div className="space-y-2">
//             <Label>Shipment Type</Label>
//             <div className="flex space-x-2">
//               <Button
//                 type="button"
//                 variant={shipmentType === "sea" ? "primaryOutline" : "outline"}
//                 className="flex-1"
//                 onClick={() => setValue("shipment_type", "sea")}
//               >
//                 <Ship className="mr-2 h-4 w-4" />
//                 Sea
//               </Button>
//               <Button
//                 type="button"
//                 variant={shipmentType === "air" ? "primaryOutline" : "outline"}
//                 className="flex-1"
//                 onClick={() => setValue("shipment_type", "air")}
//               >
//                 <Plane className="mr-2 h-4 w-4" />
//                 Air
//               </Button>
//             </div>
//             <input
//               type="hidden"
//               {...register("shipment_type", { required: "Shipment type is required" })}
//             />
//             {errors.shipment_type && (
//               <p className="text-sm text-red-500">{errors.shipment_type.message}</p>
//             )}
//           </div>
//           <div className="space-y-2">
//             <Label htmlFor="note">Note</Label>
//             <Textarea
//               id="note"
//               {...register("note")}
//               placeholder="Enter note"
//             />
//             {errors.note && (
//               <p className="text-sm text-red-500">{errors.note.message}</p>
//             )}
//           </div>
//           <Button type="submit" className="w-full mt-5" disabled={requesting}>
//             {requesting ? "Submitting..." : "Submit"}
//           </Button>
//         </form>
//       </DialogContent>
//     </Dialog>
//   )
// }

export const ShippedFromSupplierDialog = ({toggleModal, shipmentID, getShipmentDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      total_packages: "",
      tracking_number: "",
      note: "",
      date: "",
      alert_customer: true
    },
  });

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/shipment/shipped-from-supplier/${shipmentID}`, {
      packages: data.total_packages,
      tracking_number: data.tracking_number,
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no'
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.packages) {
          setError('total_packages', {
            type: 'server',
            message: responseErrors.packages
          });
        }

        if (responseErrors?.tracking_number) {
          setError('tracking_number', {
            type: 'server',
            message: responseErrors.tracking_number
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Shipped from Supplier Form</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Tracking Number</Label>
            <Input
              id="tracking_number"
              {...register("tracking_number", { 
                required: "Tracking number is required",
              })}
              placeholder="Enter tracking number"
            />
            {errors.tracking_number && (
              <p className="text-sm text-red-500">{errors.tracking_number.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="total_packages">Total Packages</Label>
            <Input
              id="total_packages"
              {...register("total_packages", { 
                required: "total packages is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "total packages must be a number"
                }
              })}
              placeholder="Enter total packages"
            />
            {errors.total_packages && (
              <p className="text-sm text-red-500">{errors.total_packages.message}</p>
            )}
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="note">Note <span>(optional)</span></Label>
            <Textarea
              id="note"
              {...register("note")}
              placeholder="Enter note"
            />
            {errors.note && (
              <p className="text-sm text-red-500">{errors.note.message}</p>
            )}
          </div>

          {/* Date */}
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <div>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field }) => <DateTimePicker field={field} />}
              />
              {errors.date && (
                <p className="text-sm text-red-500">{errors.date.message}</p>
              )}
            </div>
          </div>

          {/* Alert Customer Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="alert_customer"
              {...register("alert_customer")}
              className="h-4 w-4"
            />
            <Label htmlFor="alert_customer">Alert Customer</Label>
          </div>
          {errors.alert_customer && (
            <p className="text-sm text-red-500">{errors.alert_customer.message}</p>
          )}
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const ArrivedAtTransitWarehouseDialog = ({toggleModal, shipmentID, getShipmentDetails, shipmentType}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [totalCBM, setTotalCBM] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [images, setImages] = useState([""]);

  const methods = useForm({
    defaultValues: {
      cost_per_cbm: 0,
      cost_per_kg: 0,
      packages_cbm: [{ length: "", width: "", height: "", packages: "" }],
      packages_kg: [{ weight: "", packages: "" }],
      images: [""],
      note: "",
      date: "",
      alert_customer: true
    },
  });
  const { handleSubmit, control, formState: { errors }, watch, setError, setValue, getValues } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    const validImages = data.images.filter(img => img.trim() !== "");

    let costPerCBM = shipmentType === "sea" ? data.cost_per_cbm : data.cost_per_kg;
    let packagesCBM = shipmentType === "sea" ? data.packages_cbm : data.packages_kg;

    axios.put(baseURLs.API_URL + `/orders/shipment/at-transit-warehouse/${shipmentID}`, {
      cost_per_cbm: costPerCBM,
      shipment_type: shipmentType,
      packages_cbm: JSON.stringify(packagesCBM),
      images: validImages.length > 0 ? JSON.stringify(validImages) : null,
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no'
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.packages_cbm) {
          setError('packages_cbm', {
            type: 'server',
            message: responseErrors.packages_cbm
          });
        }

        if (responseErrors?.packages_cbm) {
          setError('packages_cbm', {
            type: 'server',
            message: responseErrors.packages_cbm
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  const { fields: seaFields, append: appendSea, remove: removeSea } = useFieldArray({
    control: control,
    name: "packages_cbm",
  })

  const { fields: airFields, append: appendAir, remove: removeAir } = useFieldArray({
    control: control,
    name: "packages_kg",
  })

  const watchCostPerCbm = watch("cost_per_cbm");
  const watchCostPerKg = watch("cost_per_kg");
  const watchPackagesCbm = useWatch({ control, name: "packages_cbm" });
  const watchPackagesKg = useWatch({ control, name: "packages_kg" });

  useEffect(() => {
    let cost = 0;
    let totalWeight = 0;
    let totalCBM = 0;
    if (shipmentType === "sea" && watchCostPerCbm) {
      totalCBM = watchPackagesCbm?.reduce((acc, pkg) => {
        const volume = (Number(pkg.length) * Number(pkg.width) * Number(pkg.height)) / 1000000 // Convert to m³
        return acc + volume * Number(pkg.packages)
      }, 0)
      cost = totalCBM * Number(watchCostPerCbm)
    } else if (shipmentType === "air" && watchCostPerKg) {
      totalWeight = watchPackagesKg?.reduce((acc, pkg) => {
        return acc + Number(pkg.weight) * Number(pkg.packages)
      }, 0)
      cost = totalWeight * Number(watchCostPerKg)
    }
    setTotalCost(cost);
    setTotalCBM(totalCBM.toFixed(4));
    setTotalWeight(totalWeight.toFixed(4));
  }, [shipmentType, watchCostPerCbm, watchCostPerKg, watchPackagesCbm, watchPackagesKg])

  const handleImageChange = (index, value) => {
    const updatedImages = [...images];
    updatedImages[index] = value;
    setImages(updatedImages);
  };

   // Function to handle adding a new image input field
   const addImageField = () => {
    setImages([...images, ""]); // Add a new empty input to the images array
  };

  // Function to handle removing an image input field
  const removeImageField = (index) => {
    setImages(images.filter((_, i) => i !== index)); // Remove the selected image input
  };

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="lg:max-w-[825px] sm:max-w-[425px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Arrived at Transit Warehouse Form</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            {shipmentType === "sea" && (
              <FormField
                control={control}
                name="cost_per_cbm"
                rules={{ 
                  required: "Cost per CBM is required",
                  validate: (value) =>
                    parseFloat(value) > 0 || "Unit cost must be greater than zero",
                 }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-lg font-semibold">Cost per CBM</FormLabel>
                    <FormControl>
                      <div className="relative">
                        {/* Input field with prefix inside */}
                        <Input
                          {...field}
                          id="cost"
                          type="number"
                          className="pl-6 max-w-xs"
                          placeholder="Enter cost per CBM"
                          required
                        />
                        {/* Currency prefix inside the input */}
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">$</span>
                      </div>
                    </FormControl>
                    {
                      errors.cost_per_cbm && 
                      <FormMessage>{errors.cost_per_cbm}</FormMessage>
                    }
                  </FormItem>
                )}
              />
            )}

            {shipmentType === "air" && (
              <FormField
                control={control}
                name="cost_per_kg"
                rules={{ 
                  required: "Cost per KG is required", 
                  validate: (value) =>
                    parseFloat(value) > 0 || "Unit cost must be greater than zero",
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-lg font-semibold">Cost per KG</FormLabel>
                    <FormControl>
                      <div className="relative">
                        {/* Input field with prefix inside */}
                        <Input
                          {...field}
                          id="cost"
                          type="number"
                          className="pl-6 max-w-xs"
                          placeholder="Enter cost per KG"
                          required
                        />
                        {/* Currency prefix inside the input */}
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">$</span>
                      </div>
                    </FormControl>
                    {
                      errors.cost_per_kg && 
                      <FormMessage>{errors.cost_per_kg}</FormMessage>
                    }
                  </FormItem>
                )}
              />
            )}

            <Separator />

            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Package Details</h3>
              {shipmentType === "sea" && (
                <>
                  {seaFields.map((field, index) => (
                    <Card key={field.id} className="p-4">
                      <div className="grid grid-cols-4 gap-4">
                        <FormField
                          control={control}
                          name={`packages_cbm.${index}.length`}
                          rules={{ required: "Length is required" }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Length (cm)</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Length" {...field} />
                              </FormControl>
                              {
                                errors.packages_cbm?.[index]?.length && 
                                <FormMessage>{errors.packages_cbm[index]?.length?.message}</FormMessage>
                              }
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name={`packages_cbm.${index}.width`}
                          rules={{ required: "Width is required" }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Width (cm)</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Width" {...field} />
                              </FormControl>
                              {
                                errors.packages_cbm?.[index]?.width && 
                                <FormMessage>{errors.packages_cbm[index]?.width?.message}</FormMessage>
                              }
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name={`packages_cbm.${index}.height`}
                          rules={{ required: "Height is required" }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Height (cm)</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Height" {...field} />
                              </FormControl>
                              {
                                errors.packages_cbm?.[index]?.height && 
                                <FormMessage>{errors.packages_cbm[index]?.height?.message}</FormMessage>
                              }
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name={`packages_cbm.${index}.packages`}
                          rules={{ required: "Number of packages is required" }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Packages</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Number of packages" {...field} />
                              </FormControl>
                              {
                                errors.packages_cbm?.[index]?.packages && 
                                <FormMessage>{errors.packages_cbm[index]?.packages?.message}</FormMessage>
                              }
                            </FormItem>
                          )}
                        />
                      </div>
                      <Button type="button" variant="destructive" size="sm" onClick={() => removeSea(index)} className="mt-4">
                        <Trash2 className="h-4 w-4 mr-2" />
                        Remove Package
                      </Button>
                    </Card>
                  ))}
                  <Button type="button" variant="outline" onClick={() => appendSea({ length: "", width: "", height: "", packages: "" })}>
                    Add Sea Package
                  </Button>
                </>
              )}

              {shipmentType === "air" && (
                <>
                  {airFields.map((field, index) => (
                    <Card key={field.id} className="p-4">
                      <div className="grid grid-cols-2 gap-4">
                        <FormField
                          control={control}
                          name={`packages_kg.${index}.weight`}
                          rules={{
                            required: "Weight is required",
                            pattern: {
                              value: /^[0-9]+$/, // Ensures only whole numbers (0,1,2,3...)
                              message: "Weight must be a whole number",
                            },
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Weight (kg)</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  step="1" // Ensures only whole numbers
                                  min="1" // Prevents negative numbers (optional)
                                  placeholder="Weight"
                                  {...field}
                                  onKeyDown={(e) => {
                                    if (e.key === "." || e.key === "-") {
                                      e.preventDefault(); // Prevents decimal points and negative numbers
                                    }
                                  }}
                                />
                              </FormControl>
                              {errors.packages_kg?.[index]?.weight && (
                                <FormMessage>{errors.packages_kg[index]?.weight?.message}</FormMessage>
                              )}
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name={`packages_kg.${index}.packages`}
                          rules={{ required: "Number of packages is required" }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Packages</FormLabel>
                              <FormControl>
                                <Input type="number" placeholder="Number of packages" {...field} />
                              </FormControl>
                              {
                                errors.packages_kg?.[index]?.packages && 
                                <FormMessage>{errors.packages_kg[index]?.packages?.message}</FormMessage>
                              }
                            </FormItem>
                          )}
                        />
                      </div>
                      <Button type="button" variant="destructive" size="sm" onClick={() => removeAir(index)} className="mt-4">
                        <Trash2 className="h-4 w-4 mr-2" />
                        Remove Package
                      </Button>
                    </Card>
                  ))}
                  <Button type="button" variant="outline" onClick={() => appendAir({ weight: "", packages: "" })}>
                    Add Air Package
                  </Button>
                </>
              )}
            </div>

            <Separator />

            {/* Images Field */}
            <FormItem>
              <FormLabel>Image URLs</FormLabel>
                {images.map((image, index) => (
                  <div key={index}>
                    <div className="flex items-center mb-2">
                      <Controller
                        name={`images[${index}]`} // Provide unique name for each image field
                        control={control}
                        rules={{ validate: validateUrl }} // Add validation rule
                        render={({ field }) => (
                          <Input
                            {...field}
                            onChange={(e) => {
                              field.onChange(e); // Update form state with react-hook-form's onChange
                              handleImageChange(index, e.target.value); // Optional: update local state if needed
                            }}
                            placeholder="Enter image URL"
                          />
                        )}
                      />
                      {images.length > 1 && (
                        <Button
                          type="button"
                          variant="destructive"
                          onClick={() => removeImageField(index)}
                          className="ml-2"
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    {errors.images?.[index] && (
                      <FormMessage>{errors.images[index]?.message}</FormMessage>
                    )}
                  </div>
                ))}
                <Button variant="secondary" type="button" onClick={addImageField}>
                  Add another image URL
                </Button>
            </FormItem>

            <Separator />

            <FormField
              control={control}
              name="note"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-lg font-semibold">Note</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      id="note"
                      className="pl-6"
                      placeholder="Enter note"
                    />
                  </FormControl>
                  {
                    errors.note && 
                    <FormMessage>{errors.note}</FormMessage>
                  }
                </FormItem>
              )}
            />

            <Separator />
            {/* End Date */}
            <FormField name="date" control={control}
              rules={{
                required: "Date is required",
              }}
              render={({ field }) => (
                <FormItem>
                  <div>
                    <FormLabel>Date</FormLabel>
                  </div>
                  <FormControl>
                    <DateTimePicker field={field} />
                  </FormControl>
                  {errors.date && <FormMessage>{errors.date.message}</FormMessage>}
                </FormItem>
              )}
            />

            {/* Alert Customer Checkbox */}
            <FormField
              control={control}
              name="alert_customer"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl>
                    <input
                      type="checkbox"
                      id="alert_customer"
                      {...field}
                      checked={field.value || false}
                      className="h-4 w-4"
                    />
                  </FormControl>
                  <FormLabel htmlFor="alert_customer" className="font-medium">
                    Alert Customer
                  </FormLabel>
                  {errors.alert_customer && (
                    <FormMessage>{errors.alert_customer.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />

            <Separator />

            <div>
              <Label className="text-lg font-semibold">Total Shipping Cost</Label>
              <p className="text-3xl font-bold">${totalCost.toFixed(2)} / <small>{`${shipmentType === 'sea' ? `${totalCBM} CBM` : `${totalWeight} kg`}`}</small></p>
            </div>


            <Button type="submit" className="w-full mt-5" disabled={requesting}>
              {requesting ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

export const ShippedToGhanaDialog = ({toggleModal, shipmentID, getShipmentDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      tracking_number: "",
      note: "",
      date: "",
      alert_customer: true
    },
  });

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/shipment/shipped-to-ghana/${shipmentID}`, {
      tracking_number: data.tracking_number,
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no'
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.tracking_number) {
          setError('tracking_number', {
            type: 'server',
            message: responseErrors.tracking_number
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Shipped to Ghana Form</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Tracking Number</Label>
            <Input
              id="tracking_number"
              {...register("tracking_number", { 
                required: "Tracking number is required",
              })}
              placeholder="Enter tracking number"
            />
            {errors.tracking_number && (
              <p className="text-sm text-red-500">{errors.tracking_number.message}</p>
            )}
          </div>
          <div className="space-y-2">
            <Label htmlFor="note">Note <span>(optional)</span></Label>
            <Textarea
              id="note"
              {...register("note")}
              placeholder="Enter note"
            />
            {errors.note && (
              <p className="text-sm text-red-500">{errors.note.message}</p>
            )}
          </div>

          {/* Date */}
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <div>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field }) => <DateTimePicker field={field} />}
              />
              {errors.date && (
                <p className="text-sm text-red-500">{errors.date.message}</p>
              )}
            </div>
          </div>

          {/* Alert Customer Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="alert_customer"
              {...register("alert_customer")}
              className="h-4 w-4"
            />
            <Label htmlFor="alert_customer">Alert Customer</Label>
          </div>
          {errors.alert_customer && (
            <p className="text-sm text-red-500">{errors.alert_customer.message}</p>
          )}
          
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const ArrivedInGhanaDialog = ({toggleModal, shipmentID, getShipmentDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      note: "",
      date: "",
      alert_customer: true
    },
  });

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/shipment/arrived-in-ghana/${shipmentID}`, {
      note: data.note,
      date: moment(data.date).format("YYYY-MM-DD HH:mm"),
      alert_customer: data.alert_customer ? 'yes' : 'no'
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.tracking_number) {
          setError('tracking_number', {
            type: 'server',
            message: responseErrors.tracking_number
          });
        }

        if (responseErrors?.note) {
          setError('note', {
            type: 'server',
            message: responseErrors.note
          });
        }

        if (responseErrors?.date) {
          setError('date', {
            type: 'server',
            message: responseErrors.date
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Arrived in Ghana</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="note">Note <span>(optional)</span></Label>
            <Textarea
              id="note"
              {...register("note")}
              placeholder="Enter note"
            />
            {errors.note && (
              <p className="text-sm text-red-500">{errors.note.message}</p>
            )}
          </div>

          {/* Date */}
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <div>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                render={({ field }) => <DateTimePicker field={field} />}
              />
              {errors.date && (
                <p className="text-sm text-red-500">{errors.date.message}</p>
              )}
            </div>
          </div>

          {/* Alert Customer Checkbox */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="alert_customer"
              {...register("alert_customer")}
              className="h-4 w-4"
            />
            <Label htmlFor="alert_customer">Alert Customer</Label>
          </div>
          {errors.alert_customer && (
            <p className="text-sm text-red-500">{errors.alert_customer.message}</p>
          )}
          
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

// export const ArrivedInGhanaDialog = ({toggleModal, shipmentID, getShipmentDetails}) => {
//   const [requesting, setRequesting] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');

//   const onSubmit = () => {
//     setRequesting(true);
//     setErrorMessage('');

//     axios.put(baseURLs.API_URL + `/orders/shipment/arrived-in-ghana/${shipmentID}`, null, getAxiosHeaders())
//     .then((response) => {
//       getShipmentDetails();
//       toggleModal("successDialog");
//     })
//     .catch((error) => {
//       if (error.response && error.response.data) {
//         const responseErrors = error.response.data.errors || null;
//         setErrorMessage(error.response.data.error);
//       }
//     })
//     .finally(() => {
//       setRequesting(false);
//     });
//   }

//   return (
//     <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
//       <AlertDialogContent>
//         <AlertDialogHeader>
//           <AlertDialogTitle>Updated Shipment to Arrived in Ghana?</AlertDialogTitle>
//           <AlertDialogDescription>
//             Are you sure you want to update the shipment to <b>Arrived in Ghana</b>?
//           </AlertDialogDescription>
//         </AlertDialogHeader>
//         <AlertDialogFooter>
//           <Button onClick={() => onSubmit()}>Update</Button>
//           <AlertDialogCancel>Close</AlertDialogCancel>
//         </AlertDialogFooter>
//       </AlertDialogContent>
//     </AlertDialog>
//   )
// }

export const UpdateAsReadyForPickupDialog = ({toggleModal, productIDs, getShipmentDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = () => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/status/ready_for_pickup`, {
      order_items: JSON.stringify(productIDs)
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Updated {`${productIDs.length > 1 ? `Products` : `Product`}`} to Ready for Pickup?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to update the {`${productIDs.length > 1 ? `products` : `product`}`} to <b>Ready for Pickup</b>?
            {
              errorMessage.length > 0 &&
              <p className="text-red-500">{errorMessage}</p>
            }
          </AlertDialogDescription>
        </AlertDialogHeader>
        {
          requesting ? 
          <AlertDialogFooter>
            <Button>Updating...</Button>
          </AlertDialogFooter>
          : 
          <AlertDialogFooter>
            <Button onClick={() => onSubmit()}>Update</Button>
            <AlertDialogCancel>Close</AlertDialogCancel>
          </AlertDialogFooter>
        }
        
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const UpdateAsDeliveredDialog = ({toggleModal, productIDs, getShipmentDetails}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = () => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/status/delivered`, {
      order_items: JSON.stringify(productIDs)
    }, getAxiosHeaders())
    .then((response) => {
      getShipmentDetails();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Updated {`${productIDs.length > 1 ? `Products` : `Product`}`} to Delivered?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to update the {`${productIDs.length > 1 ? `products` : `product`}`} to <b>Delivered</b>?
            {
              errorMessage.length > 0 &&
              <p className="text-red-500">{errorMessage}</p>
            }
          </AlertDialogDescription>
        </AlertDialogHeader>
        {
          requesting ? 
          <AlertDialogFooter>
            <Button>Updating...</Button>
          </AlertDialogFooter>
          : 
          <AlertDialogFooter>
            <Button onClick={() => onSubmit()}>Update</Button>
            <AlertDialogCancel>Close</AlertDialogCancel>
          </AlertDialogFooter>
        }
      </AlertDialogContent>
    </AlertDialog>
  )
}

