import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  FileUp,
  PackageCheck,
  PackageOpen,
  Plus,
  Search,
  ShoppingCart,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { Badge } from "../../components/ui/badge";
import { getAxiosHeaders, getQueryParams } from "../../misc/utils";
import axios from "axios";
import { baseURLs } from "../../misc/constants";
import { TableSkeleton } from "../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle, NoResults } from "../../components/ui/alert";
import { Checkbox } from "../../components/ui/checkbox";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "../../components/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import moment from "moment";
import { useForm } from "react-hook-form";

const AddProductCategoryDialog = ({toggleModal, getCategories}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      category_name: "",
    },
  });

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    axios.post(baseURLs.API_URL + `/products/category/add`, {
      category_name: data.category_name,
    }, getAxiosHeaders())
    .then((response) => {
      toggleModal("successAddCategoryDialog");
      getCategories();
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.category_name) {
          setError('category_name', {
            type: 'server',
            message: responseErrors.category_name
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Product Category</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Category Name</Label>
            <Input
              id="category_name"
              {...register("category_name", { 
                required: "Category name is required",
              })}
              placeholder="Enter category name"
            />
            {errors.category_name && (
              <p className="text-sm text-red-500">{errors.category_name.message}</p>
            )}
          </div>          
          
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

const EditProductCategoryDialog = ({toggleModal, categoryInfo, getCategories}) => {
  const [requesting, setRequesting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm({
    defaultValues: {
      category_name: categoryInfo.category_name,
    },
  });

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/products/category/edit/${categoryInfo.category_id}`, {
      category_name: data.category_name,
    }, getAxiosHeaders())
    .then((response) => {
      toggleModal("successEditCategoryDialog");
      getCategories();
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);

        // Check for specific field errors and set them
        if (responseErrors?.category_name) {
          setError('category_name', {
            type: 'server',
            message: responseErrors.category_name
          });
        }
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  return (
    <Dialog open={true} onOpenChange={() => toggleModal(null)}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Product Category</DialogTitle>
        </DialogHeader>
        {
          errorMessage &&
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        }
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="order_number">Category Name</Label>
            <Input
              id="category_name"
              {...register("category_name", { 
                required: "Category name is required",
              })}
              placeholder="Enter category name"
            />
            {errors.category_name && (
              <p className="text-sm text-red-500">{errors.category_name.message}</p>
            )}
          </div>          
          
          <Button type="submit" className="w-full mt-5" disabled={requesting}>
            {requesting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export const ProductCategories = () => {
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    },
    data: []
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [singleSelectDelete, setSingleSelectDelete] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [editCategoryInfo, setEditCategoryInfo] = useState({});
  const [filters, setFilters] = useState({    
    page: 1,
    category: ''
  });

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const resetFilter = () => {
    let params = {
      page: 1,
      category: ''
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getCategories(params);
  }

  const filterCategories = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getCategories(filters);
  }

  // Toggle "select all" functionality
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allRowIds = tableData.data.map((category, index) => category.category_id);
      setSelectedRows(allRowIds);
      setCheckAll(true);
    } else {
      // If unchecked, clear all selected rows
      setSelectedRows([]);
      setCheckAll(false);
    }
  };

  // Toggle individual row selection
  const handleRowSelect = (category_id) => {
    if (selectedRows.includes(category_id)) {
      // Remove row from selection if already selected
      setSelectedRows(selectedRows.filter((row) => row !== category_id));
    } else {
      // Add row to selection if not already selected
      setSelectedRows([...selectedRows, category_id]);
    }
  };

  const navigate = useNavigate();

  const getCategories = (filters) => {
    setLoadingProducts(true);
    axios.get(baseURLs.API_URL + "/products/category", {
      headers: getAxiosHeaders().headers,
      params: {
        page: filters.page ?? 1,
        category: filters.category,
        limit: 10
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 200) {
        setTableData({
          meta: responseInfo.data.meta,
          data: responseInfo.data.category,
        });

        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
      } else {
        setTableData({
          meta: {
            total_records: 0,
          },
          data: []
        })
      }

      
      currentUrl(filters);
      setLoadingProducts(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingProducts(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  // Delete all selected rows
  const handleDeleteSelected = () => {
    if (selectedRows.length === 0 && !singleSelectDelete) {
        setErrorMessage(`Select at least one category to delete`);
        toggleModal('errorDialog');
    } else {
        toggleModal('deleteCategoryDialog');
    }
  };

  const handleSingleDelete = (categoryID) => {
    setSingleSelectDelete(categoryID);
  };

  const resetDelete = () => {
    setSelectedRows([]);
    setSingleSelectDelete('');
  }

  useEffect(() => {
    if(singleSelectDelete) {
      handleDeleteSelected();
    }
  },[singleSelectDelete])

  const confirmDelete = () => {
    checkAll ? deleteCategoriesByFilter() : deleteCategoriesByIDs();    
  };

  const deleteCategoriesByIDs = () => {
    setRequesting(true);

    let _checkedBoxes = singleSelectDelete ? [singleSelectDelete] : selectedRows ;

    axios.delete(baseURLs.API_URL + "/products/category/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        category: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getCategories(filters);        
        toggleModal('successDialog');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getCategories(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `categories` : `category`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `categories` : `category`} failed to get deleted`);
        toggleModal('errorDialog');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `categories` : `category`}. The selected ${failedIDs.length > 1 ? `categories were` : `category was`} not found.`);
        toggleModal('errorDialog');
      }

      setSelectedRows([]);
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const deleteCategoriesByFilter = () => {
    setRequesting(false);

    axios.delete(baseURLs.API_URL + "/products/category/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        category_name: filters.category
      }
    })
    .then((response) => {
      getCategories(filters);
      toggleModal('successDialog');
      setCheckAll(false);
      setRequesting(false);
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('errorDialog');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
      setRequesting(false);
    });
  }

  const currentUrl = (filters) => {
    let category_search = filters.category.length > 0 ? `&c=${filters.category}` : ``;
    
    if( (category_search !== '' ) && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${category_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    navigate(url, { replace: true })
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getCategories(params)
  }

  const allSelected = selectedRows.length === tableData.data.length;

  const editCategory = (categoryInfo) => {
    setEditCategoryInfo(categoryInfo);
  }

  useEffect(() => {
    toggleModal('editProductCategory');
  }, [editCategoryInfo])

  useEffect(() => {
    setCheckAll(allSelected)
  },[allSelected])

  useEffect(() => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let category = queryParams.hasOwnProperty('c') ? queryParams.c : '';

    let params = filters;
    params.page = page;
    params.category = category;

    getCategories(params);
  }, [])

  return (
    <React.Fragment>
      <Head title="Products" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Product Categories</h1>
          <div className="flex gap-x-2">
            <Button onClick={() => toggleModal('addNewProductCategory')}><Plus className="mr-2" /> Add New Category</Button>
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/products`} />
          </div>
        </div>

        {
          loadingProducts ?
          <Card x-chunk="dashboard-05-chunk-3">
            <TableSkeleton />
          </Card>
          :
          <Card x-chunk="dashboard-05-chunk-3">
            <CardHeader className="px-7 flex-row justify-between">
              <CardTitle className="text-lg content-center">{tableData.meta.total_records} {tableData.meta.total_records > 1 ? `Categories` : `Category`}</CardTitle>
              <Button variant="outline" onClick={() => toggleModal("filterModal")}><Search className="w-4 h-4 mr-2"  />Filter</Button>
            </CardHeader>
            <CardContent>
              {
                tableData.data.length === 0 ?
                <NoResults message="No products found" />
                :
                <>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>
                          <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
                        </TableHead>
                        <TableHead>Category Name</TableHead>
                        <TableHead className="hidden sm:table-cell">
                          Date
                        </TableHead>
                        <TableHead>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost">
                                <DotsVerticalIcon className="h-5 w-5" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem onClick={handleDeleteSelected}>
                                Delete Selected
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        tableData.data.map((data, index) => {
                          const isSelected = selectedRows.includes(data.category_id);

                          return (
                            <TableRow key={index} className="cursor-pointer">
                              <TableCell>
                                <Checkbox
                                  checked={isSelected}
                                  onCheckedChange={() => handleRowSelect(data.category_id)}
                                />
                              </TableCell>
                              <TableCell>
                                <div>{data.category_name}</div>
                              </TableCell>
                              <TableCell className="hidden md:table-cell">
                                {moment(data.created_at).format("Do MMM YYYY")}
                              </TableCell>
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost">
                                      <DotsVerticalIcon className="h-5 w-5" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    <DropdownMenuItem onClick={() => editCategory(data)}>
                                      Edit
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className="text-red-500" onClick={() => handleSingleDelete(data.category_id)}>
                                      Delete
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                  <div className="mt-5 justify-center">
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
                  </div>
                </>
              }
            </CardContent>
          </Card>
        }
        {
          activeModal === "deleteCategoryDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedRows.length === 1 ? "Delete Category" : "Delete Selected Categories"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedRows.length === 1
                    ? "Are you sure you want to delete this category? This action cannot be undone."
                    : "Are you sure you want to delete all selected categories? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => resetDelete()}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Deleting...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={confirmDelete}>Confirm</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successAddCategoryDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Category Added</AlertDialogTitle>
                <AlertDialogDescription>
                  The category has been added successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successEditCategoryDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Category Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The category has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Deleted Successfully</AlertDialogTitle>
                <AlertDialogDescription>
                  The category deleted successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "errorDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal("errorDialog")}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Error</AlertDialogTitle>
                <AlertDialogDescription>
                  {errorMessage}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === 'filterModal' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader className="mb-4">
                <DialogTitle>Filter Category</DialogTitle>
              </DialogHeader>
                <div className="space-y-4">
                  <div className="grid gap-2">
                    <Label htmlFor="category_name">Category Name</Label>
                    <Input
                      id="category_name"
                      name="category_name"
                      type="text"
                      placeholder="Enter a category name"
                      onChange={onInputChange}
                      defaultValue={filters.category_name}
                    />
                  </div>
                </div>

                {/* Dialog Footer */}
                <DialogFooter>
                  <Button onClick={(ev) => { ev.preventDefault(); filterCategories();} }>Apply Filter</Button>
                  {
                    hasFilters &&
                    <Button className="ms-3" variant="secondary" onClick={(ev) => { ev.preventDefault(); resetFilter();} }>
                      Reset Filter
                    </Button>
                  }
                </DialogFooter>
            </DialogContent>
          </Dialog>
        }

        {
          activeModal === 'addNewProductCategory' &&
          <AddProductCategoryDialog toggleModal={toggleModal} getCategories={() => getCategories(filters)} />
        }

        {
          activeModal === 'editProductCategory' &&
          <EditProductCategoryDialog toggleModal={toggleModal} getCategories={() => getCategories(filters)} categoryInfo={editCategoryInfo} />
        }
      </main>
    </React.Fragment>
  )
}
