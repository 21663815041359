import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertCircle,
  Bell,
  ChevronDown,
  MoreHorizontal,
  MoreVertical,
  Plus,
} from "lucide-react"
import { Button, GoBackButton } from "../../components/ui/button";
import Head from "../../layouts/Head";
import { getAxiosHeaders, getInvoiceStatusColor, OrderStatus } from "../../misc/utils";
import { baseURLs } from "../../misc/constants";
import axios from "axios";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import moment from "moment";
import { Badge } from "../../components/ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { 
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "../../components/ui/table";
import { Input } from "../../components/ui/input";
import { Alert, AlertDescription, AlertTitle, NoResults } from "../../components/ui/alert";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator } from "../../components/ui/dropdown-menu";
import { Checkbox } from "../../components/ui/checkbox";
import { PaginationWithOnclick } from "../../components/PaginationWithOnClick";
import { CustomOrderedShipmentDialog, DealOrderedShipmentDialog, EditSourcedProductQuantityDialog } from "../components/OrderUpdateForms";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../../components/ui/alert-dialog";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { ProductSelect, ProductVariantSelect } from "../components/ProductSelect";
import { FormProvider, useForm } from "react-hook-form";
import { Separator } from "../../components/ui/separator";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export const OrderDetails = () => {
  const ITEMS_PER_PAGE = 10;
  const { orderID } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [orderInfo, setOrderInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeModal, setActiveModal] = useState(null);
  
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedCancelInvoice, setSelectedCancelInvoice] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSourcedProduct, setSelectedSourcedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Initialize the form methods
  const methods = useForm({
    defaultValues: {
      product_id: "",
      moq: ""
    },
  });

  const { handleSubmit, control, formState: { errors }, setError } = methods;

  const onSubmit = (data) => {
    setRequesting(true);
    setErrorMessage('');

  
    axios.put(`${baseURLs.API_URL}/orders/sourcing/${orderID}`, {
      product: data.product_id,
      moq: data.moq
    }, getAxiosHeaders())
      .then((response) => {
        let responseInfo = response.data;
        setRequesting(false);
        toggleModal('successSourcedDialog');
        getOrderInfo();
        
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseErrors = error.response.data.errors;
          setErrorMessage(error.response.data.error);
  
          // Check for specific field errors and set them
          if (responseErrors.product) {
            setError('product', {
              type: 'server',
              message: responseErrors.product
            });
          }
  
          if (responseErrors.moq) {
            setError('moq', {
              type: 'server',
              message: responseErrors.moq
            });
          }
          
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };  

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const handleOrderStatusUpdate = () => {
    setRequesting(true);
    setErrorMessage('');

    axios.put(baseURLs.API_URL + `/orders/status/${orderID}`, {
      status: selectedStatus,
    }, getAxiosHeaders())
    .then((response) => {
      getOrderInfo();
      toggleModal("successDialog");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const responseErrors = error.response.data.errors || null;
        setErrorMessage(error.response.data.error);
      }
    })
    .finally(() => {
      setRequesting(false);
    });
  }

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage('');
  };

  const filteredProducts = orderInfo?.products?.filter((product) =>
    Object.values(product).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  ) || [];

  const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE)
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedProducts(paginatedProducts.map((product) => product.order_product_id))
    } else {
      setSelectedProducts([])
    }
  }

  const handleSelectOrder = (orderProductId, checked) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, orderProductId])
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== orderProductId))
    }
  }

  const handleUpdateQuantity = (product) => {
    setSelectedSourcedProduct(product);
  }

  useEffect(() => {
    if(selectedSourcedProduct) {
      toggleModal("editSourcedProductQuantityModal");
    }
  },[selectedSourcedProduct]);

  const handlePageChange = (page) => {
    setCurrentPage(page)
    setSelectedProducts([])
  }

  const handleBulkAction = (action) => {
    console.log(`Applying bulk action: ${action} to orders:`, selectedProducts)
    if(action === 'remove_products'){
      removeSourcedProducts();
    }
  }

  const getOrderInfo = () => {
    setLoading(true);
    axios.get(`${baseURLs.API_URL}/orders/${orderID}`, {
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      if (response.status === 204) {
        navigate(`${process.env.PUBLIC_URL}/not-found`);
        return;
      }

      setOrderInfo(responseInfo.data.order_info);
      setSelectedStatus(responseInfo.data.order_info.status);
      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  const getProducts = (product) => {
    setLoadingProducts(true);
    axios.get(baseURLs.API_URL + "/products", {
      headers: getAxiosHeaders().headers,
      params: {
        product_name: product
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      if (responseInfo.status === 200) {
        setProducts(responseInfo.data.products);
      } else {
        setProducts([]);
      }

      setLoadingProducts(false);
            
    }).catch((error) => {
      console.log({error})
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingProducts(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  };

  // Debounced version of fetchProducts
  const debouncedFetchProducts = useCallback(debounce(getProducts, 500), []);

  // remove selected sourced products
  const removeSourcedProducts = () => {
    setRequesting(true);
    setErrorMessage('');

    axios.delete(`${baseURLs.API_URL}/orders/sourcing/${orderID}`, {
      headers: getAxiosHeaders().headers,
      data: {
        products: JSON.stringify(selectedProducts)
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      setRequesting(false);
      toggleModal('successRemovalDialog');
      setSelectedProducts([]);
      getOrderInfo();
            
    }).catch((error) => {
      try{
        let errorResponse = error.response?.data ?? '';

        if(error.response.status === 401){
          navigate(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          navigate(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          navigate(`${process.env.PUBLIC_URL}/unauthorized`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setRequesting(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
      }
    });
  }

  // remove selected sourced products
  // const handleCancelInvoice = () => {
  //   setRequesting(true);
  //   setErrorMessage('');

  //   axios.put(`${baseURLs.API_URL}/orders/invoice/cancel/${orderID}`, {
  //     invoice_id: selectedCancelInvoice.invoice_id
  //   }, getAxiosHeaders())
  //   .then((response) => {
  //     let responseInfo = response.data;
  //     setRequesting(false);
  //     toggleModal('successCancelInvoiceDialog');
  //     getOrderInfo();
            
  //   }).catch((error) => {
  //     try{
  //       let errorResponse = error.response?.data ?? '';

  //       if(error.response.status === 401){
  //         navigate(`${process.env.PUBLIC_URL}/expired-session`);
  //         return;
  //       }

  //       if(error.response.status === 404){
  //         navigate(`${process.env.PUBLIC_URL}/not-found`);
  //         return;
  //       }

  //       if(error.response.status === 403){
  //         navigate(`${process.env.PUBLIC_URL}/unauthorized`);
  //         return;
  //       }

  //       let errorMessage = 'Error: Could not connect to server';
  //       if(errorResponse.hasOwnProperty("error")){
  //         errorMessage = errorResponse.error;
  //       }

  //       setRequesting(false);
  //       setErrorMessage(errorMessage);
  //     }catch(e){
  //       console.log(e);
  //     }
  //   });
  // }

  // const cancelInvoice = (invoice) => {
  //   setSelectedCancelInvoice(invoice);
  // }

  const updateInvoiceBalance = (invoice) => {
    setSelectedInvoice(invoice);
  }

  // useEffect(() => {
  //   if(selectedCancelInvoice) {
  //     toggleModal("cancelInvoiceDialog");
  //   }
  // }, [selectedCancelInvoice]);

  useEffect(() => {
    if(selectedInvoice) {
      toggleModal("updateInvoiceBalanceDialog");
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if(!orderID){
      navigate(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    getOrderInfo();
  }, [])

  return (
    <React.Fragment>
      <Head title="Order Details" />
      <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6 lg:px-20">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold md:text-2xl">Order Details - {`#${orderID}`}</h1>
          <div>
            {
              orderInfo.status !== 'cancelled' &&
              <Button className="mr-2" onClick={() => toggleModal('updateOrderStatus')}>Update Order Status</Button>
            }
            <GoBackButton fallbackRoute={`${process.env.PUBLIC_URL}/orders`} />
          </div>
        </div>
        {
          loading ?
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
            <div className="md:col-span-7">
              <Skeleton className="h-[360px] rounded-xl" />
            </div>
          </div>
          :
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="md:col-span-5 gap-8">
              <Card>
                <CardHeader>
                  <CardTitle className="text-md">Customer Info</CardTitle>
                </CardHeader>
                <CardContent className="gap-8">
                  <div>
                    <span className="text-sm font-medium text-gray-500">Name:</span>
                    <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_name}</p>
                  </div>
                  <div>
                    <span className="text-sm font-medium text-gray-500">Phone Number:</span>
                    <p className="text-lg font-semibold text-gray-800">{orderInfo.customer_phone_number}</p>
                  </div>
                </CardContent>
              </Card>
              {
                orderInfo.images.length > 0 &&
                <Card className="mt-5">
                  <CardHeader>
                    <CardTitle className="text-md">Uploaded Images</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-3 gap-4">
                      {/* Image thumbnails */}
                      {
                        orderInfo.images.map((image, index) => {
                          return (
                            <img src={image} key={index}
                              className="cursor-pointer w-24 h-24 object-cover rounded-md border border-gray-200"
                              onClick={() => openModal(image)}
                            />
                          )
                        })
                      }                      
                    </div>
                  </CardContent>
                </Card>
              }

              <Card className="mt-5">
                <CardHeader>
                  <CardTitle className="text-md">Invoices</CardTitle>
                </CardHeader>
                <CardContent>
                  {
                    orderInfo.invoices.length === 0 ?
                    <NoResults message="No invoice found" />
                    :
                    <div className="divide-y">
                      {orderInfo.invoices.map((invoice) => (
                        <div key={invoice.sale_id} className="py-4 flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                          <div className="flex-grow">
                            <div className="flex items-center mb-2">
                              <h3 className="text-lg font-semibold">#{invoice.invoice_number}</h3>
                              <Badge className={`ml-2 ${getInvoiceStatusColor(invoice.status)}`}>{invoice.status}</Badge>
                              <Badge className="ml-2">{invoice.type}</Badge>
                            </div>
                            <div className="grid-cols-1 sm:grid-cols-2 text-sm">
                              <div className="flex items-center font-medium mb-2">
                                {
                                  invoice.status === 'receipt' ?
                                  <span>Total: GHS {invoice.total_amount}</span>
                                  :
                                  <span>Total: GHS {invoice.amount_paid} / {invoice.total_amount}</span>
                                }
                              </div>
                              <div className="flex items-center">
                                {/* <Calendar className="h-4 w-4 mr-2 text-muted-foreground" /> */}
                                <span>{moment(invoice.created_at).format("Do MMM YYYY hh:mm a")}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="ghost" size="sm">
                                  <MoreVertical className="h-4 w-4" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuItem onClick={() => window.open(invoice.invoice_link, '_blank')}>
                                  View Details
                                </DropdownMenuItem>
                                {
                                  invoice.status === 'invoice' &&
                                  <>
                                    <DropdownMenuItem onClick={() => updateInvoiceBalance(invoice)}>
                                      Update Balance
                                    </DropdownMenuItem>
                                    {/* <DropdownMenuItem onClick={() => cancelInvoice(invoice)}>
                                      Cancel Invoice
                                    </DropdownMenuItem> */}
                                  </>
                                }
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </CardContent>
              </Card>

              {/* Modal for Image Pop-up */}
              {modalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="relative">
                    <img src={currentImage} alt="Selected" className="max-h-[500px] object-contain rounded-md" />
                    <button
                      onClick={closeModal}
                      className="absolute top-2 right-2 text-white text-3xl"
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="md:col-span-7">
              <div className="grid md:col-span-5 gap-8">
                <Card>
                  <CardHeader>
                    <CardTitle className="text-md">Order Info</CardTitle>
                  </CardHeader>
                  <CardContent className="grid gap-6">
                    <div className="grid sm:grid-cols-2 gap-4">
                      <div>
                        <span className="text-sm font-medium text-gray-500">Order Type:</span>
                        <p className="text-md font-semibold text-gray-800">
                          <Badge className="capitalize" variant="secondary">
                            {`${orderInfo.order_type} order`}
                          </Badge>
                        </p>
                      </div>
                      <div>
                        <span className="text-sm font-medium text-gray-500">Order Tracking Number:</span>
                        <p className="text-md font-semibold text-gray-800">{orderInfo.order_tracking_number}</p>
                      </div>

                      <div>
                        <span className="text-sm font-medium text-gray-500">Service Cost:</span>
                        <p className="text-md font-semibold text-gray-800">GHS {orderInfo.service_cost}</p>
                      </div>
                      <div>
                        <span className="text-sm font-medium text-gray-500">Total Cost:</span>
                        <p className="text-md font-semibold text-gray-800">GHS {orderInfo.total_amount}</p>
                      </div>

                      <div>
                        <span className="text-sm font-medium text-gray-500">Order Status:</span>
                        <p className="text-md font-semibold text-gray-800">
                          <OrderStatus status={orderInfo.status} />
                        </p>
                        
                      </div>
                      <div>
                        <span className="text-sm font-medium text-gray-500">Created By:</span>
                        <p className="text-md font-semibold text-gray-800">{orderInfo.created_by}</p>
                      </div>
                      <div>
                        <span className="text-sm font-medium text-gray-500">Created:</span>
                        <p className="text-md font-semibold text-gray-800">{moment(orderInfo.created_at).format("Do MMM YYYY hh:mm a")}</p>
                      </div>
                    </div>
                    
                  </CardContent>
                </Card>

                {
                  orderInfo.status === 'requested' ?
                  <Card>
                    <CardHeader className="px-7 flex-row justify-between">
                      <CardTitle className="text-md content-center">{`${orderInfo.products.length} ${orderInfo.products.length > 1 ? `Sourced Products` : `Sourced Product`}`} </CardTitle>
                      <div className="flex flex-row gap-2">
                        <Button variant="outline" onClick={() => toggleModal("addSourcedProduct")}><Plus className="w-4 h-4 mr-2"  />Add Product</Button>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="ml-auto" disabled={selectedProducts.length === 0}>
                              Bulk Actions <ChevronDown className="ml-2 h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className="text-red-500" onClick={() => toggleModal("removeSourcedProductsDialog")}>
                              Remove Selected Product
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </CardHeader>
                    
                    <CardContent>
                      
                    {
                      orderInfo.products.length === 0 ?
                      <NoResults message="No product found" />
                      :
                      <>
                        <Table>
                          <TableHeader>
                            <TableRow>
                            <TableHead className="w-[50px]">
                                <Checkbox
                                  checked={
                                    paginatedProducts.length > 0 &&
                                    selectedProducts.length === paginatedProducts.length
                                  }
                                  onCheckedChange={handleSelectAll}
                                />
                              </TableHead>
                              <TableHead>Image</TableHead>
                              <TableHead>Product</TableHead>
                              <TableHead className="sm:table-cell">
                                MOQ
                              </TableHead>
                              <TableHead className="sm:table-cell">
                                Unit Cost
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {
                              paginatedProducts.map((product, index) => {
                                return (
                                  <TableRow key={index} className="cursor-pointer">
                                    <TableCell>
                                      <Checkbox
                                        checked={selectedProducts.includes(product.product_id)}
                                        onCheckedChange={(checked) =>
                                          handleSelectOrder(product.product_id, checked)
                                        }
                                      />
                                      
                                    </TableCell>
                                    <TableCell className="hidden sm:table-cell">
                                      <img
                                        alt={product.product_name}
                                        className="aspect-square rounded-md object-cover"
                                        height="44"
                                        src={product.image}
                                        width="44"
                                      />
                                    </TableCell>
                                    <TableCell onClick={() => console.log(product.order_product_id)}>
                                      <div className="font-medium">
                                        <div className="font-medium">{product.product_name}</div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="hidden md:table-cell">
                                      {product.moq}
                                    </TableCell>
                                    <TableCell className="hidden md:table-cell">
                                      GHS {product.unit_cost}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            }
                            
                          </TableBody>
                        </Table>
                        <div className="mt-5 justify-center">
                          <PaginationWithOnclick currentPage={currentPage} pageCount={totalPages} loadNextPage={handlePageChange} />
                        </div>
                      </>
                    }
                    </CardContent>
                  </Card>
                  :
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-md">Products</CardTitle>
                    </CardHeader>
                    <CardContent>
                    {
                      orderInfo.products.length === 0 ?
                      <NoResults message="No product found" />
                      :
                      <>
                        <div className="flex justify-between items-center mb-4">
                          <Input
                            type="text"
                            placeholder="Search product..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="max-w-sm"
                          />
                          {
                            orderInfo.status !== 'cancelled' &&
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="ml-auto" disabled={selectedProducts.length === 0}>
                                  Bulk Actions <ChevronDown className="ml-2 h-4 w-4" />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuSeparator />
                                <DropdownMenuItem onClick={() => toggleModal(orderInfo.order_type === 'deal' ? "updateDealAsOrderedModal" : "updateCustomAsOrderedModal")}>
                                  Update as Ordered
                                </DropdownMenuItem>
                                <DropdownMenuItem className="text-red-500" onClick={() => handleBulkAction("cancelOrder")}>
                                  Cancel Orders
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          }
                        </div>
                        <Table>
                          <TableHeader>
                            <TableRow>
                            <TableHead className="w-[50px]">
                              {
                                orderInfo.status !== 'cancelled' &&
                                <Checkbox
                                  checked={
                                    paginatedProducts.length > 0 &&
                                    selectedProducts.length === paginatedProducts.length
                                  }
                                  onCheckedChange={handleSelectAll}
                                />
                              }
                              </TableHead>
                              <TableHead>Image</TableHead>
                              <TableHead>Product</TableHead>
                              <TableHead className="sm:table-cell">
                                Status
                              </TableHead>
                              <TableHead className="sm:table-cell">
                                Quantity
                              </TableHead>
                              <TableHead className="sm:table-cell">
                                Total
                              </TableHead>
                              
                              {
                                orderInfo.status === 'pending_approval' &&
                                <TableHead className="sm:table-cell">
                                  <DotsVerticalIcon className="h-5 w-5" />
                                </TableHead>
                              }
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {
                              paginatedProducts.map((product, index) => {
                                return (
                                  <TableRow key={index} className="cursor-pointer">
                                    <TableCell>
                                      {
                                        orderInfo.status !== 'cancelled' &&
                                        <Checkbox
                                          checked={selectedProducts.includes(product.order_product_id)}
                                          onCheckedChange={(checked) =>
                                            handleSelectOrder(product.order_product_id, checked)
                                          }
                                        />
                                      }
                                      
                                    </TableCell>
                                    <TableCell className="hidden sm:table-cell">
                                      <img
                                        alt={product.product_name}
                                        className="aspect-square rounded-md object-cover"
                                        height="44"
                                        src={product.image}
                                        width="44"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <div className="font-medium">
                                        <div className="font-medium" onClick={() => navigate(`${process.env.PUBLIC_URL}/products/details/${product.parent_product_id}`)}>{product.product_name}</div>
                                        <div className="text-xs text-muted-foreground">
                                          GHS {product.unit_cost}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="hidden sm:table-cell capitalize font-bold">
                                      <OrderStatus status={orderInfo.status === 'cancelled' ? 'cancelled' : product.status} />
                                      <div><small>({product.updated_by ?? product.created_by})</small></div>
                                    </TableCell>
                                    <TableCell className="hidden md:table-cell">
                                      {product.quantity}
                                    </TableCell>
                                    <TableCell className="hidden md:table-cell">
                                      GHS {product.total_product_cost}
                                    </TableCell>

                                    {
                                      orderInfo.status === 'pending_approval' &&
                                      <TableCell>
                                        <DropdownMenu>
                                          <DropdownMenuTrigger asChild>
                                            <Button variant="ghost">
                                              <DotsVerticalIcon className="h-5 w-5" />
                                            </Button>
                                          </DropdownMenuTrigger>
                                          <DropdownMenuContent>
                                            <DropdownMenuItem onClick={() => handleUpdateQuantity(product)}>
                                              Update Quantity
                                            </DropdownMenuItem>
                                          </DropdownMenuContent>
                                        </DropdownMenu>
                                      </TableCell>
                                    }
                                  </TableRow>
                                )
                              })
                            }
                            
                          </TableBody>
                        </Table>
                        <div className="mt-5 justify-center">
                          <PaginationWithOnclick currentPage={currentPage} pageCount={totalPages} loadNextPage={handlePageChange} />
                        </div>
                      </>
                    }
                    </CardContent>
                  </Card>
                }

                
              </div>
            </div>
          </div>
        }

        {
          activeModal === 'updateDealAsOrderedModal' &&
          <DealOrderedShipmentDialog toggleModal={toggleModal} orders={selectedProducts} getDealOrders={getOrderInfo} />
        }

        {
          activeModal === 'updateCustomAsOrderedModal' &&
          <CustomOrderedShipmentDialog toggleModal={toggleModal} orders={selectedProducts} getOrderDetails={getOrderInfo} />
        }

        {
          activeModal === 'editSourcedProductQuantityModal' &&
          <EditSourcedProductQuantityDialog toggleModal={toggleModal} sourcedProduct={selectedSourcedProduct} setSelectedSourcedProduct={setSelectedSourcedProduct} getOrderDetails={getOrderInfo} />
        }

        {
          activeModal === "updateOrderStatus" &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Update Order Status</DialogTitle>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
                <RadioGroup value={selectedStatus} onValueChange={(value) => setSelectedStatus(value)}>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="requested" id="requested" />
                    <Label htmlFor="requested">Requested</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="pending_approval" id="pending_approval" />
                    <Label htmlFor="pending_approval">Pending Approval</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="pending_payment" id="pending_payment" />
                    <Label htmlFor="pending_payment">Pending Payment</Label>
                  </div>
                  {/* <div className="flex items-center space-x-2">
                    <RadioGroupItem value="ordered" id="ordered" />
                    <Label htmlFor="ordered">Ordered</Label>
                  </div> */}
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="processing" id="processing" />
                    <Label htmlFor="processing">Processing</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="on_hold" id="on_hold" />
                    <Label htmlFor="on_hold">On Hold</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="completed" id="completed" />
                    <Label htmlFor="completed">Completed</Label>
                  </div>     
                  <hr/>  
                  <div className="flex items-center space-x-2 text-red-500">
                    <RadioGroupItem value="cancelled" id="cancelled" />
                    <Label htmlFor="cancelled">Cancel Order</Label>
                  </div>              
                </RadioGroup>
              </div>
              <Button onClick={handleOrderStatusUpdate}>{requesting ? "Updating..." : "Update Status"}</Button>
            </DialogContent>
          </Dialog>
        }

        {
          activeModal === 'addSourcedProduct' &&
          <Dialog open={true} onOpenChange={() => toggleModal(null)}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add Sourced Product</DialogTitle>
              </DialogHeader>
              {
                errorMessage &&
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>
                    {errorMessage}
                  </AlertDescription>
                </Alert>
              }
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div className="space-y-2">
                    <FormField
                      name="product_id"
                      control={control}
                      rules={{
                        required: "Product is required",
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product</FormLabel>
                          <FormControl>
                            <ProductVariantSelect 
                              field={field}
                              onChange={(value) => field.onChange(value)}
                            />
                          </FormControl>
                          {errors.product_id && (
                            <FormMessage>{errors.product_id.message}</FormMessage>
                          )}
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-2">
                    <FormField name="moq" control={control}
                      rules={{
                        required: "Service rate is required",
                        validate: {
                          isPercentage: (value) =>
                            value >= 0 && value <= 100
                              ? true
                              : "Service rate must be a percentage (0-100)",
                        },
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>MOQ</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              className="pr-10" // Add space for the suffix
                            />
                          </FormControl>
                          {errors.moq && <FormMessage>{errors.moq.message}</FormMessage>}
                        </FormItem>
                      )}
                    />
                  </div>
                  <Button type="submit" className="w-full mt-5" disabled={requesting}>
                    {requesting ? "Submitting..." : "Submit"}
                  </Button>
                </form>
                <Separator />
                <Button onClick={() => {navigate(`${process.env.PUBLIC_URL}/products/add?order=${orderID}`);}} variant="outline">Add a New Product</Button>
              </FormProvider>
            </DialogContent>
          </Dialog>
        }

        {
          activeModal === "removeSourcedProductsDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {selectedProducts.length === 1 ? "Remove Selected Product" : "Remove Selected Products"}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {selectedProducts.length === 1
                    ? "Are you sure you want to remove this sourced product? This action cannot be undone."
                    : "Are you sure you want to delete all selected sourced products? This action cannot be undone."}
                </AlertDialogDescription>
              </AlertDialogHeader>
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => toggleModal(null)}>Cancel</AlertDialogCancel>
                {
                  requesting ?
                  <AlertDialogAction disabled>Removing...</AlertDialogAction>
                  :
                  <AlertDialogAction className="bg-red-600 text-white hover:bg-red-700" onClick={() => removeSourcedProducts()}>Remove</AlertDialogAction>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {/* {
          activeModal === "cancelInvoiceDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Cancel Invoice?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to cancel this invoice? This action cannot be undone.
                </AlertDialogDescription>
              </AlertDialogHeader>
                {
                  errorMessage &&
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {errorMessage}
                    </AlertDescription>
                  </Alert>
                }
              <AlertDialogFooter>
                <AlertDialogCancel onClick={() => {toggleModal(null); setSelectedCancelInvoice("")}}>Close</AlertDialogCancel>
                {
                  requesting ?
                  <Button disabled>Cancelling...</Button>
                  :
                  <Button className="bg-red-600 text-white hover:bg-red-700" onClick={() => handleCancelInvoice()}>Cancel Invoice</Button>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successCancelInvoiceDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Invoice Cancelled</AlertDialogTitle>
                <AlertDialogDescription>
                  The invoice has been cancelled successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        } */}

        {
          activeModal === "successSourcedDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Sourced Product Added</AlertDialogTitle>
                <AlertDialogDescription>
                  The sourced product has been added to the order successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successRemovalDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Sourced Products Removed</AlertDialogTitle>
                <AlertDialogDescription>
                  The selected sourced product(s) has been removed successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Order Status Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The order status has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successOrderDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Product(s) Status Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The product(s) status has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }

        {
          activeModal === "successOrderProductUpdateDialog" &&
          <AlertDialog open={true} onOpenChange={() => toggleModal(null)}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Product Quantity Updated</AlertDialogTitle>
                <AlertDialogDescription>
                  The product quantity has been updated successfully
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        }
      </main>
    </React.Fragment>
  )
}
